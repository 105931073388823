import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      index: 1
    },
    component: () => import('@/views/login/login')
  },
  {
    path: '/kf2',
    name: 'kf2',
    meta: {
      index: 1
    },
    component: () => import('@/views/kefu/kf2'),
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      index: 2
    },
    component: () => import('@/views/login/register')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index'),
    children: [
      {
        path: '/index',
        name: 'index',
        meta: {
          index: 1
        },
        component: () => import('@/views/index/index'),
      },
      {
        path: '/order',
        name: 'order',
        meta: {
          index: 1
        },
        component: () => import('@/views/order/order'),
      },
      {
        path: '/task',
        name: 'task',
        meta: {
          index: 1
        },
        component: () => import('@/views/task/task'),
      },

      {
        path: '/kefu',
        name: 'kefu',
        meta: {
          index: 1
        },
        component: () => import('@/views/kefu/kefu'),
      },

      {
        path: '/mine',
        name: 'mine',
        meta: {
          index: 1
        },
        component: () => import('@/views/mine/mine'),
      },
    ]
  },
  {
    path: '/kefu3',
    name: 'kefu3',
    meta: {
      index: 1
    },
    component: () => import('@/views/kefu/kefu3'),
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/recharge'),
  },
  {
    path: '/rechargeDetail',
    name: 'rechargeDetail',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/rechargeDetail'),
  },
  //   rechargeDetail
  {
    path: '/withdraw',
    name: 'withdraw',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/withdraw'),
  },
  {
    path: '/address',
    name: 'address',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/address'),
  },
  {
    path: '/bind',
    name: 'bind',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/bind'),
  },
  {
    path: '/bindCard',
    name: 'bindCard',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/bindCard'),
  },
  {
    path: '/bindCredit',
    name: 'bindCredit',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/bindCredit'),
  },
  {
    path: '/share',
    name: 'share',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/share'),
  },
  {
    path: '/record',
    name: 'record',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/record'),
  },
  {
    path: '/team',
    name: 'team',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/team'),
  },
  {
    path: '/company',
    name: 'company',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/company'),
  },
  {
    path: '/header',
    name: 'header',
    meta: {
      index: 2
    },
    component: () => import('@/views/mine/header'),
  },
  {
    path: '/download',
    name: 'download',
    meta: {
      index: 3
    },
    component: () => import('@/views/download/download'),
  },
  {
    path: '/to-login',
    name: 'to-login',
    meta: {
      index: 2
    },
    component: () => import('@/views/login/tologin'),
  },

  {
    path: '*',
    redirect: '/index'
  }
]

const router = new VueRouter({
  routes
})

const whiteArr = ['register', 'login', 'index', 'lang','download',"kf2","kefu","to-login"]
router.beforeEach((to, from, next) => {
  let token = getToken()
  if (token) {
    next()
  } else {
    if (whiteArr.indexOf(to.name) > -1) {
      next()
    } else {
      next('login')
      // next()
    }
  }
})

router.afterEach(() => {
  window.scrollTo({top: 0})
})

export default router
