import axios from "axios";
import Vue from "vue";
import { Toast } from 'vant';
import { getToken, removeToken } from "./auth";
import router from '../router'
Vue.use(Toast);
// 创建axios实例
const service = axios.create({
  baseURL: '', // api的base_url
  timeout: 100000, // 请求超时时间
  headers: { "Cache-Control": "no-cache", Pragma: "no-cache"}
});

// 添加请求拦截器
service.interceptors.request.use(
  request => {
    let token = getToken();
    if (token) {
      request.headers['X-Token'] = token;
    }
    request.headers.language = localStorage.getItem('locale') || 'en'
    return request
  },
  error => {
    return Promise.reject(error);
  }
);

// 拦截返回的数据res,通过返回值直接获取到服务器的数据
service.interceptors.response.use(
  response => {
    
    const res = response.data;
    return res
  },
  error => {
    if (error.response.status == 401 || error.response.status == 403) {
      removeToken()
      router.push({
        path: '/login'
      })
    }
    return Promise.reject(error);
  }
);

export default service;
